/* =================================================
 Custom hook for accessing settings parts - roles hotkeys
 =================================================== */
import { useContext, useEffect } from 'react'
import { SettingsContext } from 'src/state/SettingsProvider'
import { RolesHotkeysActionTypes } from 'src/models/settings'
import { Hotkey } from 'src/models/hotkeys'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useToast } from '@verbit-ai/verbit-ui-library'
import { APIError } from 'src/network'
import { useSpeakers } from 'src/state/SpeakersProvider'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

export const useRoleHotkeys = () => {
    const { settingsState, dispatch } = useContext(SettingsContext)
    const { speakers, updateSpeaker, updateSpeakerState } = useSpeakers()
    const analytics = useAnalytics()
    const [{ context: appContext }] = useAppMachine(['httpClient'])
    const { httpClient } = appContext
    const toast = useToast()

    useEffect(() => {
        if (!settingsState?.roles?.roleHotkeys) {
            void fetchRoleHotkeys()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsState?.roles?.roleHotkeys])

    const setRoleHotkeys = (hotkeys: Hotkey[]) => {
        dispatch({
            type: RolesHotkeysActionTypes.SET_ROLE_HOTKEYS,
            roleHotkeys: hotkeys,
        })
    }

    const fetchRoleHotkeys = async () => {
        try {
            const data = await httpClient.getHotkeys('role')
            setRoleHotkeys(data)
        } catch (err) {
            showErrorToast(err, 'Error getting hotkeys')
            console.error(err)
        }
    }

    const addRoleHotkeys = async (hotkey: Hotkey) => {
        const speakersToUpdate = speakers.filter((speaker) => speaker.role === hotkey.name)

        try {
            const data = await httpClient.addHotkey(hotkey)
            const newHotkeys = settingsState.roles.roleHotkeys
                ? settingsState.roles.roleHotkeys.concat(data)
                : [data]
            setRoleHotkeys(newHotkeys)
            setCustomRole('')

            // Update speakers that have the new hotkey as custom role - set it as hotkey
            if (speakersToUpdate.length) {
                for (const speaker of speakersToUpdate) {
                    updateSpeaker({
                        ...speaker,
                        role: '',
                        hotkey: data,
                    })
                }
            }
        } catch (err) {
            showErrorToast(err, 'Failed to create hotkey')
            console.error(err)
        }
    }

    const updateRoleHotkeys = async (hotkey: Hotkey) => {
        const speakersToUpdate = speakers.filter((speaker) => speaker.hotkey?.id === hotkey.id)

        const oldHotkey = settingsState.roles.roleHotkeys?.find(
            (roleHotkey) => roleHotkey.id === hotkey.id,
        )
        if (!oldHotkey) return

        try {
            // update state immediately for smooth UI reactions
            const newHotkeys = settingsState.roles.roleHotkeys?.map((roleHotkey) =>
                hotkey.id === roleHotkey.id ? hotkey : roleHotkey,
            )
            newHotkeys && setRoleHotkeys(newHotkeys)
            await httpClient.updateHotkey(hotkey)

            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.HOTKEYS,
                ANALYTICS_CONSTS.Speakers.SPEAKER_ROLE_HOTKEY_CHANGED,
            )

            // Update speakers that connected to this hotkey
            if (speakersToUpdate.length) {
                for (const speaker of speakersToUpdate) {
                    updateSpeakerState({
                        ...speaker,
                        role: '',
                        hotkey: hotkey,
                    })
                }
            }
        } catch (err) {
            showErrorToast(err, 'Failed to update hotkey')
            console.error(err)

            const newHotkeys = settingsState.roles.roleHotkeys?.map((roleHotkey) =>
                hotkey.id === roleHotkey.id ? oldHotkey : roleHotkey,
            )

            // revert the update of hotkey in state
            newHotkeys && setRoleHotkeys(newHotkeys)
        }
    }

    const deleteRoleHotkeys = async (id: string) => {
        const speakersToUpdate = speakers.filter((speaker) => speaker.hotkey?.id === id)
        try {
            // Detach existing speakers from hotkey that is going to be deleted
            if (speakersToUpdate.length) {
                for (const speaker of speakersToUpdate) {
                    updateSpeakerState({
                        ...speaker,
                        role: speaker.hotkey?.name || '',
                        hotkey: undefined,
                    })
                }
            }
            // delete hotkey
            const success = await httpClient.deleteHotkey(id)

            if (success) {
                const newHotkeys = settingsState.roles.roleHotkeys?.filter(
                    (roleHotkey) => id !== roleHotkey.id,
                )
                newHotkeys && setRoleHotkeys(newHotkeys)
            }
        } catch (err) {
            showErrorToast(err, 'Failed to delete hotkey')
            console.error(err)
        }
    }

    const showErrorToast = (err: unknown, title: string) => {
        if (err instanceof APIError) {
            toast({
                type: 'error',
                title: title,
                description: err.message,
                duration: 5000,
            })
        }
    }

    const setCustomRole = (newCustomRole: string) => {
        dispatch({
            type: RolesHotkeysActionTypes.SET_CUSTOM_ROLE,
            customHotkey: newCustomRole,
        })
    }

    const focusOnRole = (role: string) => {
        dispatch({
            type: RolesHotkeysActionTypes.SET_FOCUSED_ROLE,
            focusedRole: role,
        })
    }

    return {
        roleHotkeys: settingsState?.roles?.roleHotkeys,
        customRole: settingsState?.roles?.customRoleName,
        focusedRole: settingsState?.roles?.focusedRole,
        addRoleHotkeys,
        updateRoleHotkeys,
        deleteRoleHotkeys,
        setCustomRole,
        focusOnRole,
    }
}
