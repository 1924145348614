import { Event as ChatEvent, Channel } from '@verbit-ai/chat-frontend/lib/types'

import { getMixpanelApiKey } from 'src/utils/env'
import {
    ValidationsAnalyticsMetadata,
    ValidationsCount,
} from 'src/components/Editor/plugins/withValidations'
import { GlossaryTermCategory, TranscriptionLayerId, FeatureFlag } from 'src/models'

import {
    SessionEndReason,
    SESSION_ID_TITLE,
    PLATFORM_ID_TITLE,
    REASON_TITLE,
    TASK_ID_TITLE,
    TASK_TYPE_TITLE,
    TRAINING_TITLE,
    TASK_LAYER_ID_TITLE,
    ASSIGNED_AT_TITLE,
    EDITABLE_AUDIO_LENGTH_TITLE,
    ALLOTTED_TIME_FOR_TASK_TITLE,
    TASK_WORK_DURATION_TITLE,
    ONBOARDING_STEPS_LIST,
    PUBLISH_TYPE_TITLE,
    REMAINING_TIME_ON_TIMER_TITLE,
    TERM_ID_TITLE,
    TERM_TYPE_TITLE,
    TERM_IS_VERIFIED_TITLE,
    GLOSSARY_TERM_TEXT_TITLE,
    GLOSSARY_ORIGINAL_TRIGGER_TITLE,
    GLOSSARY_OPEN_SOURCE_TITLE,
    GLOSSARY_EVENT_SOURCE_TITLE,
    SUGGESTION_TERM_TEXT_TITLE,
    SUGGESTION_ORIGINAL_TRIGGER_TITLE,
    CURRENT_STEP,
    KEYBOARD_SHORTCUT_ACTION,
    KEYBOARD_SHORTCUT_COMBO,
    SPELLING_ERRORS_COUNT_START,
    SPELLING_ERRORS_COUNT_END,
    SCREEN_RESOLUTION,
    DEVICE_PIXEL_RATIO,
    GlossaryOpenSource,
    SELECTED_SPEED,
    VALIDATIONS_INVALID_TERMS_COUNT_START,
    VALIDATIONS_INVALID_TERMS_COUNT_END,
    CURRENT_VALIDATIONS_STATUS,
    VALIDATION_ID,
    VALIDATION_NAME,
    VALIDATIONS_TRIGGER_STRING,
    VALIDATIONS_TRIGGER_TIMING,
    VALIDATIONS_AFTER_STRING,
    SPEAKERS_SEPARATION_COUNT,
    CUSTOMER_ID,
    CUSTOMER_NAME,
    STRATEGY_TITLE,
    REPLACEMENT_SUGGESTION,
    VERTICAL_TITLE,
    PRESSED_KEY_TITLE,
    TASK_AUTO_SUBMISSION,
    CHAT_MESSAGE_CONTENT_TITLE,
    CHAT_QUOTED_MESSAGE_CONTENT_TITLE,
    CHAT_MESSAGE_ATTACHMENTS_TITLE,
    CHAT_CHANNEL_MEMBERS_TITLE,
    CHAT_CHANNEL_ONLINE_MEMBERS_TITLE,
    TASK_AUDIO_LISTEN_TIME,
    TASK_USER_IDLE_TIME,
    TASK_TIME_WAITED_FOR_ASSIGNMENT,
    TASK_IS_FIRST_IN_LAYER,
    ONBOARDING_AUDIO_LISTEN_TIME,
    ONBOARDING_TOTAL_AUDIO_LENGTH,
    AttachmentsDownloadSource,
    ATTACHMENTS_FILE_TYPE,
    ATTACHMENTS_DOWNLOAD_SOURCE,
    ActionTriggerSource,
    ACTION_TRIGGER_SOURCE,
    MEDIA_SOURCE,
    APPLICATION_NAME_LABEL,
    APPLICATION_NAME,
    FEATURE_NAME_LABEL,
    SESSION_NAME_TITLE,
} from './types'

import { ANALYTICS_CONSTS } from 'src/analytics/constants'

import { MixPanelClient } from './mixpanel'

export interface ClientProps {
    [key: string]: any
}

interface FeatureFlags {
    [key: string]: FeatureFlag
}

export type MarkValidationEventData = {
    validationId: string
    invalidString: string
    validationName: string
    triggerTiming: string
} & ValidationsAnalyticsMetadata

export type IrrelevantValidationEventData = {
    validationId: string
    invalidString: string
    validationName: string
} & ValidationsAnalyticsMetadata

export type TaskPublishType = 'Timeout' | 'Keyboard' | 'Mouse'

export type GlossaryEventSource = 'Context Menu' | 'Glossary Panel' | 'Terms Verification Task'

export class AnalyticsClient {
    private static appName = 'Trax'
    private sessionId?: string
    private sessionName?: string
    private isTraining?: boolean
    private platJobId?: number
    private vertical?: string | null
    private strategyName?: string
    private customerId?: string
    private customerName?: string
    private layerId?: TranscriptionLayerId
    private mixpanel: MixPanelClient | undefined
    private mediaSource?: string

    constructor() {
        const mixpanelApiKey = getMixpanelApiKey()
        if (mixpanelApiKey) {
            this.mixpanel = new MixPanelClient(mixpanelApiKey)
        }
        this.mixpanel?.initialize()
        this.setDeviceRelatedProps()
    }

    mixPanelIdentify(workerId: string) {
        this.mixpanel?.identifyMixpanelUser(workerId)
    }

    async sendToClients(featureName: string, eventName: string, event: ClientProps) {
        const basicProperties: ClientProps = this.GetExtendedProperties()
        basicProperties[FEATURE_NAME_LABEL] = featureName
        const extendedEvent = {
            ...event,
            ...basicProperties,
        }
        this.mixpanel?.send(
            String(`${APPLICATION_NAME} - ${featureName} - ${eventName}`),
            extendedEvent,
        )
    }

    public GetExtendedProperties() {
        const basicProperties: ClientProps = {}
        if (this.sessionId) {
            basicProperties[SESSION_ID_TITLE] = this.sessionId
        }
        if (this.sessionName) {
            basicProperties[SESSION_NAME_TITLE] = this.sessionName
        }
        if (this.platJobId) {
            basicProperties[PLATFORM_ID_TITLE] = this.platJobId
        }
        if (this.strategyName) {
            basicProperties[STRATEGY_TITLE] = this.strategyName
        }
        if (this.vertical) {
            basicProperties[VERTICAL_TITLE] = this.vertical
        }
        if (this.customerId) {
            basicProperties[CUSTOMER_ID] = this.customerId
        }
        if (this.customerName) {
            basicProperties[CUSTOMER_NAME] = this.customerName
        }
        if (this.layerId) {
            basicProperties[TASK_LAYER_ID_TITLE] = this.layerId
        }
        if (this.mediaSource) {
            basicProperties[MEDIA_SOURCE] = this.mediaSource
        }

        basicProperties[TRAINING_TITLE] = this.isTraining
        basicProperties[APPLICATION_NAME_LABEL] = AnalyticsClient.appName
        return basicProperties
    }

    extendClientUserProps(additionalProps: ClientProps) {
        this.mixpanel?.extendUserProps(additionalProps)
    }

    setSessionId(sessionId: string) {
        this.sessionId = sessionId
    }

    setSessionName(sessionName: string) {
        this.sessionName = sessionName
    }

    setIsTraining(isTraining: boolean) {
        this.isTraining = isTraining
    }

    setPlatJobId(platJobId?: number) {
        this.platJobId = platJobId
    }

    setStrategy(strategyName?: string) {
        this.strategyName = strategyName
    }

    setVertical(vertical: string | null) {
        this.vertical = vertical
    }

    setCustomerId(customerId: string) {
        this.customerId = customerId
    }
    setCustomerName(customerName: string) {
        this.customerName = customerName
    }
    setLayerId(layerId: TranscriptionLayerId) {
        this.layerId = layerId
    }

    setMediaSource(mediaSource: string) {
        this.mediaSource = mediaSource
    }

    private setDeviceRelatedProps() {
        this.extendClientUserProps({
            [SCREEN_RESOLUTION]: `${window.screen.width}x${window.screen.height}`,
            [DEVICE_PIXEL_RATIO]: window.devicePixelRatio,
        })
    }

    sendMessageSent(channel: Channel, messageEvent: ChatEvent) {
        this.sendToClients(ANALYTICS_CONSTS.Features.CHAT, ANALYTICS_CONSTS.Legacy.MESSAGE_SENT, {
            [CHAT_MESSAGE_CONTENT_TITLE]: messageEvent.message?.text ?? 'EMPTY MESSAGE TEXT',
            [CHAT_QUOTED_MESSAGE_CONTENT_TITLE]: messageEvent.message?.quoted_message?.text,
            [CHAT_MESSAGE_ATTACHMENTS_TITLE]: messageEvent.message?.attachments?.length ?? 0,
            [CHAT_CHANNEL_MEMBERS_TITLE]: channel.data?.member_count ?? 0,
            [CHAT_CHANNEL_ONLINE_MEMBERS_TITLE]: messageEvent.watcher_count,
        })
    }

    sendJoinSession(featureFlags: FeatureFlags = {}) {
        const featureFlagsObject = Object.values(featureFlags).reduce(
            (acc, { name, enabled }) => Object.assign(acc, { [name]: enabled }),
            {},
        )

        const properties = { ...featureFlagsObject }
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Session.USER_JOIN_SESSION,
            properties,
        )
    }

    sendEndSession(reason: SessionEndReason) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Session.USER_LEAVE_SESSION,
            {
                [REASON_TITLE]: reason,
            },
        )
    }

    sendTaskAssigned(
        taskId: string,
        taskType: string,
        assignedAt: Date,
        allottedTimeForTask: number,
        editableAudioLength?: number | null,
        onboardingStepsList?: string[] | null,
        waitedForTaskSeconds?: number,
        isFirstTaskInLayer?: boolean,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Legacy.TASK_ASSIGNED_TO_USER,
            {
                [TASK_ID_TITLE]: taskId,
                [TASK_TYPE_TITLE]: taskType,
                [ASSIGNED_AT_TITLE]: assignedAt.toISOString(),
                [EDITABLE_AUDIO_LENGTH_TITLE]: editableAudioLength,
                [ALLOTTED_TIME_FOR_TASK_TITLE]: allottedTimeForTask,
                [ONBOARDING_STEPS_LIST]: onboardingStepsList,
                [TASK_TIME_WAITED_FOR_ASSIGNMENT]: waitedForTaskSeconds,
                [TASK_IS_FIRST_IN_LAYER]: isFirstTaskInLayer,
            },
        )
    }

    sendTaskSubmitted(
        taskId: string,
        taskType: string,
        publishType: TaskPublishType,
        isAutoSubmit = false,
        taskWorkDurationInSeconds: number,
        allottedTimeForTask: number,
        remainingTimeOnTask: number,
        editableAudioLength?: number | null,
        onboardingStepsList?: string[] | null,
        spellingErrorsStart?: number,
        spellingErrorsEnd?: number,
        invalidTermsCountStart?: number,
        invalidTermsCountEnd?: number,
        currentValidations?: ValidationsCount,
        userIdleTimeInSeconds?: number,
        userTimeListendToAudioInSeconds?: number,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Legacy.TASK_SUBMITTED,
            {
                [TASK_ID_TITLE]: taskId,
                [TASK_TYPE_TITLE]: taskType,
                [PUBLISH_TYPE_TITLE]: publishType,
                [TASK_AUTO_SUBMISSION]: isAutoSubmit,
                [EDITABLE_AUDIO_LENGTH_TITLE]: editableAudioLength,
                [TASK_WORK_DURATION_TITLE]: taskWorkDurationInSeconds,
                [ALLOTTED_TIME_FOR_TASK_TITLE]: allottedTimeForTask,
                [REMAINING_TIME_ON_TIMER_TITLE]: remainingTimeOnTask,
                [ONBOARDING_STEPS_LIST]: onboardingStepsList,
                [SPELLING_ERRORS_COUNT_START]: spellingErrorsStart,
                [SPELLING_ERRORS_COUNT_END]: spellingErrorsEnd,
                [VALIDATIONS_INVALID_TERMS_COUNT_START]: invalidTermsCountStart,
                [VALIDATIONS_INVALID_TERMS_COUNT_END]: invalidTermsCountEnd,
                [CURRENT_VALIDATIONS_STATUS]: currentValidations,
                [TASK_USER_IDLE_TIME]: userIdleTimeInSeconds,
                [TASK_AUDIO_LISTEN_TIME]: userTimeListendToAudioInSeconds,
            },
        )
    }

    sendOpenGlossary(taskId: string, openSource: GlossaryOpenSource) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.GLOSSARY,
            ANALYTICS_CONSTS.Operation.OPEN_GLOSSARY,
            {
                [TASK_ID_TITLE]: taskId,
                [GLOSSARY_OPEN_SOURCE_TITLE]: openSource,
            },
        )
    }

    sendAddGlossaryTerm(
        taskId: string,
        taskType: string,
        termId: string,
        termType: GlossaryTermCategory,
        termText: string,
        eventSource: GlossaryEventSource,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.GLOSSARY,
            ANALYTICS_CONSTS.Operation.ADD_A_TERM,
            {
                [TASK_ID_TITLE]: taskId,
                [TASK_TYPE_TITLE]: taskType,
                [TERM_ID_TITLE]: termId,
                [TERM_TYPE_TITLE]: termType,
                [GLOSSARY_TERM_TEXT_TITLE]: termText,
                [GLOSSARY_EVENT_SOURCE_TITLE]: eventSource,
            },
        )
    }

    sendDeleteGlossaryTerm(
        taskId: string,
        termId: string,
        termType: GlossaryTermCategory,
        termText: string,
        isTermVerified: boolean,
        eventSource: GlossaryEventSource,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.GLOSSARY,
            ANALYTICS_CONSTS.Operation.DELETE_A_TERM,
            {
                [TASK_ID_TITLE]: taskId,
                [TERM_ID_TITLE]: termId,
                [TERM_TYPE_TITLE]: termType,
                [TERM_IS_VERIFIED_TITLE]: isTermVerified ? 'yes' : 'no',
                [GLOSSARY_TERM_TEXT_TITLE]: termText,
                [GLOSSARY_EVENT_SOURCE_TITLE]: eventSource,
            },
        )
    }

    sendEditGlossaryTerm(
        taskId: string,
        termId: string,
        termType: GlossaryTermCategory,
        termText: string,
        isTermVerified: boolean,
        eventSource: GlossaryEventSource,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.GLOSSARY,
            ANALYTICS_CONSTS.Operation.EDIT_A_TERM,
            {
                [TASK_ID_TITLE]: taskId,
                [TERM_ID_TITLE]: termId,
                [TERM_TYPE_TITLE]: termType,
                [TERM_IS_VERIFIED_TITLE]: isTermVerified ? 'yes' : 'no',
                [GLOSSARY_TERM_TEXT_TITLE]: termText,
                [GLOSSARY_EVENT_SOURCE_TITLE]: eventSource,
            },
        )
    }

    sendUseGlossaryTerm(
        taskId: string,
        termId: string,
        termType: GlossaryTermCategory,
        termText: string,
        isTermVerified: boolean,
        eventSource: GlossaryEventSource,
        originalText?: string,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.GLOSSARY,
            ANALYTICS_CONSTS.Operation.USE_A_TERM,
            {
                [TASK_ID_TITLE]: taskId,
                [TERM_ID_TITLE]: termId,
                [TERM_TYPE_TITLE]: termType,
                [TERM_IS_VERIFIED_TITLE]: isTermVerified ? 'yes' : 'no',
                [GLOSSARY_TERM_TEXT_TITLE]: termText,
                [GLOSSARY_ORIGINAL_TRIGGER_TITLE]: originalText,
                [GLOSSARY_EVENT_SOURCE_TITLE]: eventSource,
            },
        )
    }

    sendMarkUnclear(actionTriggerSource: ActionTriggerSource) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.MENU,
            ANALYTICS_CONSTS.Operation.MARK_UNCLEAR,
            {
                [ACTION_TRIGGER_SOURCE]: actionTriggerSource,
            },
        )
    }

    sendUseSuggestion(
        taskId: string,
        termId: string,
        termType: GlossaryTermCategory,
        termText: string,
        isTermVerified: boolean,
        originalText?: string,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Legacy.ACCEPT_SUGGESTION,
            {
                [TASK_ID_TITLE]: taskId,
                [TERM_ID_TITLE]: termId,
                [TERM_TYPE_TITLE]: termType,
                [TERM_IS_VERIFIED_TITLE]: isTermVerified ? 'yes' : 'no',
                [SUGGESTION_TERM_TEXT_TITLE]: termText,
                [SUGGESTION_ORIGINAL_TRIGGER_TITLE]: originalText,
            },
        )
    }

    sendShowGlossarySuggestions(taskId: string) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Legacy.SHOW_SUGGESTION,
            {
                [TASK_ID_TITLE]: taskId,
            },
        )
    }

    sendClearGlossarySuggestion(
        taskId: string,
        isTermVerified: boolean,
        originalText?: string,
        suggestionText?: string,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.CONTEXT_MENU,
            ANALYTICS_CONSTS.Legacy.REMOVE_SUGGESTION,
            {
                [TASK_ID_TITLE]: taskId,
                [SUGGESTION_ORIGINAL_TRIGGER_TITLE]: originalText,
                [REPLACEMENT_SUGGESTION]: suggestionText,
                [TERM_IS_VERIFIED_TITLE]: isTermVerified ? 'yes' : 'no',
            },
        )
    }

    sendOnboardingStepNavigation(
        taskId: string,
        assignedAt: Date,
        currentStep: string,
        totalAudioLength?: number,
        userTimeListenedToAudioInSeconds?: number,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.ONBOARDING,
            ANALYTICS_CONSTS.Legacy.STEP_NAVIGATION,
            {
                [TASK_ID_TITLE]: taskId,
                [ASSIGNED_AT_TITLE]: assignedAt.toISOString(),
                [CURRENT_STEP]: currentStep,
                [ONBOARDING_TOTAL_AUDIO_LENGTH]: totalAudioLength,
                [ONBOARDING_AUDIO_LISTEN_TIME]: userTimeListenedToAudioInSeconds,
            },
        )
    }

    sendKeyboardShortcutTrigger(action: string, combo: string) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Hotkeys.KEYBOARD_SHORTCUT_USED,
            {
                [KEYBOARD_SHORTCUT_ACTION]: action,
                [KEYBOARD_SHORTCUT_COMBO]: combo,
            },
        )
    }

    sendAudioSpeedChange(taskId: string, selectedSpeed: number) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.AUDIO_PANEL,
            ANALYTICS_CONSTS.Legacy.AUDIO_SPEED_CHANGE,
            {
                [TASK_ID_TITLE]: taskId,
                [SELECTED_SPEED]: `${selectedSpeed}x`,
            },
        )
    }

    sendValidationInvalidTermMarked(data: MarkValidationEventData) {
        const { validationId, invalidString, validationName, id: taskId, triggerTiming } = data
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Legacy.VALIDATION_TRIGGERED,
            {
                [VALIDATION_ID]: validationId,
                [VALIDATION_NAME]: validationName,
                [TASK_ID_TITLE]: taskId,
                [VALIDATIONS_TRIGGER_STRING]: invalidString,
                [VALIDATIONS_TRIGGER_TIMING]: triggerTiming,
            },
        )
    }

    sendIrrelevantValidation(data: IrrelevantValidationEventData) {
        const { validationId, validationName, invalidString, id: taskId } = data
        this.sendToClients(
            ANALYTICS_CONSTS.Features.VALIDATION,
            ANALYTICS_CONSTS.Legacy.REPORT_VALIDATION,
            {
                [VALIDATION_ID]: validationId,
                [VALIDATION_NAME]: validationName,
                [TASK_ID_TITLE]: taskId,
                [VALIDATIONS_TRIGGER_STRING]: invalidString,
            },
        )
    }

    sendSpeakersSeparationCount(taskId: string, separationCount: number) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.SPEAKERS,
            ANALYTICS_CONSTS.Legacy.SPEAKERS_SEPARATION_COUNT,
            {
                [TASK_ID_TITLE]: taskId,
                [SPEAKERS_SEPARATION_COUNT]: separationCount,
            },
        )
    }

    sendValidationBeforeAndAfterStrings(
        beforeString: string,
        afterString: string,
        validationId: string,
    ) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.VALIDATION,
            ANALYTICS_CONSTS.Legacy.VALIDATION_BEFORE_AFTER_STRINGS,
            {
                [VALIDATIONS_TRIGGER_STRING]: beforeString,
                [VALIDATIONS_AFTER_STRING]: afterString,
                [VALIDATION_ID]: validationId,
            },
        )
    }

    sendTypingAttemptOccurInGlossersMode(
        taskId: string,
        taskType: string,
        pressedKey: string,
    ): void {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.GLOSSARY,
            ANALYTICS_CONSTS.Operation.GLOSSERS_TRY_TO_EDIT,
            {
                [TASK_ID_TITLE]: taskId,
                [TASK_TYPE_TITLE]: taskType,
                [PRESSED_KEY_TITLE]: pressedKey,
            },
        )
    }

    sendAttachmentDownloaded(fileType: string, source: AttachmentsDownloadSource) {
        this.sendToClients(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Operation.FILE_DOWNLOADED,
            {
                [ATTACHMENTS_FILE_TYPE]: fileType,
                [ATTACHMENTS_DOWNLOAD_SOURCE]: source,
            },
        )
    }

    /* Dynamic Analytic Event Message Handler */
    sendEventTrigger(feature: string, event: string): void {
        this.sendToClients(feature, event, {})
    }
}
