import React, { useCallback, useEffect, useState } from 'react'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { Classes, Popover2 } from '@blueprintjs/popover2'
import {
    Container,
    SectionContainer,
    BlockContainer,
    PopOverContainer,
    PopOverHeader,
    PopoverText,
    PopOverAction,
    SpeakerChangeReminderContainer,
    VisibilityContainer,
} from './BlockStyled'
import { LegalExhibit, Speaker } from 'src/models'
import { useLegalAnnotations } from 'src/state/LegalAnnotationsProvider'
import { useSpeakers, useSpeakersById } from 'src/state/SpeakersProvider'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'
import { useIsSpeakerBoxVisible } from 'src/components/Editor/plugins/withTranscript/hooks/useIsSpeakerBoxVisible'
import { SpeakerLine } from 'src/widgets/SpeakerLine/SpeakerLine'
import { useSpeakerChangeReminder } from '../hooks/useSpeakerChangeReminder'
import { Block, ExaminationLegalAnnotation, isExaminationLegalAnnotation } from '../Block'
import { BurntInTimeCode } from './BurntInTimeCode'
import { LiveTimeCode } from './LiveTimeCode'
import { useEditorMeta } from 'src/components/Editor/EditorContext'
import { SpeakerBox } from './SpeakerBox'
import { SectionBox } from './SectionBox'
import { EventsBox } from './EventsBox'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'
import useVisibility from 'src/hooks/useVisibility'
import Logrocket from 'logrocket'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

interface SpeakerChangeReminderProps {
    element: Block
}

const SpeakerChangeReminder = ({ element }: SpeakerChangeReminderProps) => {
    const { dismissPopup } = useSpeakerChangeReminder(element)

    const dismiss = useCallback(
        (e: React.SyntheticEvent) => {
            e.preventDefault()
            dismissPopup()
        },
        [dismissPopup],
    )
    return (
        <SpeakerChangeReminderContainer>
            <PopOverHeader>Not the same speaker?</PopOverHeader>
            <PopoverText>
                Mark it with <i>Enter</i>.
            </PopoverText>
            <PopOverAction onMouseDown={dismiss}>Don't show this again</PopOverAction>
        </SpeakerChangeReminderContainer>
    )
}

interface BlockViewProps {
    element: Block
    attributes: any
    children: any
}

export const BlockView = ({ element, attributes, children }: BlockViewProps) => {
    const editor = useSlateStatic()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const { speakersByLegalAnnotation, legalAnnotationsBySpeaker } = useLegalAnnotations()
    const { updateSpeaker, speakers } = useSpeakers()
    const speakersById = useSpeakersById()
    const analytics = useAnalytics()

    const { controls } = useEditorMeta()
    const speakerBoxVisible = useIsSpeakerBoxVisible(controls)
    const [pendingLegalAnnotation, setPendingLegalAnnotation] =
        useState<ExaminationLegalAnnotation | null>(null)
    const [isSectionTimeCodeRemoved, setIsSectionTimeCodeRemoved] = useState(false)
    const timeCodeVisible = controls?.timecode?.visible
    const liveTimeCodeVisible = controls?.liveTimecode?.visible
    const isSectionCalibrationAnchor = element?.section === 'calibration_anchor'
    let speakerObj = element.speakerId !== null && speakersById[element.speakerId]
    const eventsMarkingFlag = useFeatureFlag('add_events_marking')

    useEffect(() => {
        if (element?.pendingLegalAnnotation) {
            setPendingLegalAnnotation(
                element.pendingLegalAnnotation as ExaminationLegalAnnotation | null,
            )
        }
    }, [element?.pendingLegalAnnotation])

    useEffect(() => {
        if (editor.shouldUpdateSpeakerWithAnnotation) {
            const isLegalAnnotationInExaminationLegalAnnotation = Object.values(
                ExaminationLegalAnnotation,
            ).includes(editor.shouldUpdateSpeakerWithAnnotation?.qac as ExaminationLegalAnnotation)

            // RND-42176: Update speaker if the legal annotation is changed, to support 2.5 seconds ASR
            // NOTE: Incoming ASR updates will not automatically assign legal annotations to speakers
            // RND-42386: Only update speaker if the legal annotation is in the ExaminationLegalAnnotation enum
            if (
                editor.shouldUpdateSpeakerWithAnnotation?.id &&
                isLegalAnnotationInExaminationLegalAnnotation
            ) {
                const newSpeakerObject = speakersById[editor.shouldUpdateSpeakerWithAnnotation?.id]
                Logrocket.log(
                    'Updating Speaker',
                    editor.shouldUpdateSpeakerWithAnnotation?.id,
                    newSpeakerObject?.name,
                    editor.shouldUpdateSpeakerWithAnnotation?.qac,
                )
                updateSpeaker({
                    ...newSpeakerObject,
                    id: editor.shouldUpdateSpeakerWithAnnotation?.id || '',
                    name: newSpeakerObject?.name || '',
                    qac: editor.shouldUpdateSpeakerWithAnnotation?.qac,
                })
            }

            editor.shouldUpdateSpeakerWithAnnotation = undefined
        }
    }, [
        editor,
        editor.shouldUpdateSpeakerWithAnnotation,
        element,
        speakerObj,
        updateSpeaker,
        speakersById,
    ])

    const handleSectionChange = useCallback(
        (section?: string) => {
            const path = ReactEditor.findPath(editor, element)
            Block.setSection(editor, path, section)

            // also delete the timecode when the section is removed
            timeCodeVisible && section === undefined
                ? setIsSectionTimeCodeRemoved(true)
                : setIsSectionTimeCodeRemoved(false)
        },
        [editor, element, timeCodeVisible],
    )

    const handleLegalAnnotationChange = useCallback(
        (legalAnnotation: string) => {
            if (
                isExaminationLegalAnnotation(legalAnnotation) &&
                speakersByLegalAnnotation[legalAnnotation] &&
                element.speakerId &&
                element.speakerId !== speakersByLegalAnnotation[legalAnnotation]
            ) {
                setPendingLegalAnnotation(legalAnnotation)
            } else {
                const path = ReactEditor.findPath(editor, element)
                Block.setLegalAnnotation(editor, path, legalAnnotation)
            }
        },
        [editor, element, speakersByLegalAnnotation],
    )

    const handleSpeakerChange = useCallback(
        (speaker: Speaker | null) => {
            const path = ReactEditor.findPath(editor, element)

            if (!element.speakerId) {
                analytics?.sendEventTrigger(
                    ANALYTICS_CONSTS.Features.SPEAKERS,
                    ANALYTICS_CONSTS.Speakers.SPEAKER_ASSIGNED,
                )
            } else {
                analytics?.sendEventTrigger(
                    ANALYTICS_CONSTS.Features.SPEAKERS,
                    ANALYTICS_CONSTS.Speakers.SPEAKER_CHANGED,
                )
            }

            Block.setSpeaker(editor, path, speaker)
        },
        [editor, element, analytics],
    )

    const handleLegalExhibitChange = useCallback(
        (exhibit?: LegalExhibit) => {
            const path = ReactEditor.findPath(editor, element)
            Block.setLegalExhibit(editor, path, exhibit)
        },
        [editor, element],
    )

    const onAcceptPendingLegalAnnotations = useCallback(() => {
        if (!pendingLegalAnnotation) return

        const path = ReactEditor.findPath(editor, element)
        Block.setLegalAnnotation(editor, path, pendingLegalAnnotation)
        const isPendingLegalAnnotationInExaminationLegalAnnotation = Object.values(
            ExaminationLegalAnnotation,
        ).includes(pendingLegalAnnotation as ExaminationLegalAnnotation)

        // RND-42176: Update speaker if the legal annotation is changed, to support 2.5 seconds ASR
        // NOTE: Incoming ASR updates will not automatically assign legal annotations to speakers
        // RND-42386: Only update speaker if the pending legal annotation is in the ExaminationLegalAnnotation enum
        if (
            element.speakerId !== null &&
            !!speakerObj &&
            isPendingLegalAnnotationInExaminationLegalAnnotation
        ) {
            const speakerToReplaceColloquy = Object.keys(legalAnnotationsBySpeaker).find(
                (key) => legalAnnotationsBySpeaker[key] === pendingLegalAnnotation,
            )
            const filteredSpeakerEntries = Object.entries(speakers)
                .filter(([key, value]) => value.id === speakerToReplaceColloquy)
                .map(([key, value]) => ({ [key]: value }))

            const filteredSpeakerObject = Object.assign({}, ...filteredSpeakerEntries)

            // Update both speakers with the new legal annotation assigned
            if (
                filteredSpeakerObject &&
                typeof filteredSpeakerObject === 'object' &&
                !Array.isArray(filteredSpeakerObject)
            ) {
                const prevSpeaker = Object.keys(filteredSpeakerObject)[0]
                if (prevSpeaker !== undefined) {
                    // update previous speaker assignment to colloquy
                    Logrocket.log(
                        'Updating speaker to Colloquy',
                        filteredSpeakerObject[prevSpeaker].id,
                        filteredSpeakerObject[prevSpeaker].name,
                        'c',
                    )

                    updateSpeaker({
                        ...filteredSpeakerObject[prevSpeaker],
                        id: filteredSpeakerObject[prevSpeaker].id,
                        name: filteredSpeakerObject[prevSpeaker].name,
                        qac: 'c',
                    })

                    // update current speaker assignment to the new legal annotation
                    Logrocket.log(
                        'Updating speaker to new annotation',
                        speakerObj?.id,
                        speakerObj?.name,
                        pendingLegalAnnotation,
                    )
                    updateSpeaker({
                        ...speakerObj,
                        id: speakerObj?.id || '',
                        name: speakerObj?.name || '',
                        qac: pendingLegalAnnotation,
                    })
                }
            }

            editor.shouldUpdateSpeakerWithAnnotation = undefined
        }

        Block.setPendingLegalAnnotation(editor, path, null)
        setPendingLegalAnnotation(null)
    }, [
        editor,
        element,
        pendingLegalAnnotation,
        speakerObj,
        updateSpeaker,
        legalAnnotationsBySpeaker,
        speakers,
    ])

    const onCancelPendingLegalAnnotation = useCallback(() => {
        setPendingLegalAnnotation(null)
        const path = ReactEditor.findPath(editor, element)
        Block.setPendingLegalAnnotation(editor, path, null)
    }, [editor, element])

    const blockHasSection = !!element.section
    const sectionBoxVisible = controls?.section?.visible && (element.editable || blockHasSection)

    // We should show the popup only if it wasn't already displayed in this task.
    const shouldShowSpeakerLine = controls.diarization.visible
    const { isPopupOpen: isSpeakerChangePopupOpen } = useSpeakerChangeReminder(element)
    const { isVisible, visibilityRef } = useVisibility(
        document.querySelector('#scrollingWrapper'),
        '2000px 0px',
        element.__isNewBlock,
    )

    return (
        <Container
            {...attributes}
            editable={element.editable}
            sectionsVisible={controls.section.visible}
            hasExplicitBreak={element.hasExplicitBreak}
        >
            <VisibilityContainer ref={visibilityRef}>
                {isVisible &&
                    sectionBoxVisible &&
                    (!isRealTimeReadOnly || element.section !== undefined) && (
                        <>
                            {timeCodeVisible && (
                                <BurntInTimeCode
                                    element={element}
                                    editor={editor}
                                    controls={controls}
                                    isSectionTimeCodeRemoved={isSectionTimeCodeRemoved}
                                    setIsSectionTimeCodeRemoved={setIsSectionTimeCodeRemoved}
                                />
                            )}

                            {liveTimeCodeVisible && !isRealTimeReadOnly && (
                                <LiveTimeCode
                                    element={element}
                                    isRealTimeReadOnly={isRealTimeReadOnly}
                                />
                            )}

                            <SectionContainer contentEditable={false}>
                                {eventsMarkingFlag ? (
                                    <EventsBox
                                        element={element}
                                        editor={editor}
                                        section={element.section}
                                        controls={controls}
                                        blockEditable={element.editable && !isRealTimeReadOnly}
                                        onSectionChange={handleSectionChange}
                                        isCalibrationAnchorDisabled={isSectionCalibrationAnchor}
                                    />
                                ) : (
                                    <SectionBox
                                        element={element}
                                        editor={editor}
                                        section={element.section}
                                        controls={controls}
                                        blockEditable={element.editable && !isRealTimeReadOnly}
                                        onSectionChange={handleSectionChange}
                                        isCalibrationAnchorDisabled={isSectionCalibrationAnchor}
                                    />
                                )}
                            </SectionContainer>
                        </>
                    )}
                <BlockContainer>
                    {isVisible && speakerBoxVisible && (
                        <>
                            <SpeakerBox
                                element={element}
                                editor={editor}
                                selectedSpeakerId={element.speakerId}
                                speakerMarkedAsUnknown={element.speakerMarkedAsUnknown}
                                legal={element.legal}
                                controls={controls}
                                isBlockEditable={element.editable && !isRealTimeReadOnly}
                                pendingLegalAnnotation={pendingLegalAnnotation}
                                onSpeakerChange={handleSpeakerChange}
                                onLegalAnnotationChange={handleLegalAnnotationChange}
                                onLegalExhibitChange={handleLegalExhibitChange}
                                onAcceptPendingLegalAnnotations={onAcceptPendingLegalAnnotations}
                                onCancelPendingLegalAnnotation={onCancelPendingLegalAnnotation}
                                timeCodeVisible={timeCodeVisible || liveTimeCodeVisible}
                                isRealTimeReadOnly={isRealTimeReadOnly}
                            />

                            {/* Timecode for Live Viewer Mode is in different placement
                        which is under the speaker name */}
                            {liveTimeCodeVisible && isRealTimeReadOnly && (
                                <LiveTimeCode
                                    element={element}
                                    isRealTimeReadOnly={isRealTimeReadOnly}
                                />
                            )}
                        </>
                    )}
                    {isVisible && shouldShowSpeakerLine && (
                        <>
                            <PopOverContainer contentEditable={false}>
                                <Popover2
                                    placement="bottom"
                                    popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                                    isOpen={isSpeakerChangePopupOpen}
                                    renderTarget={({ isOpen, ...rest }) => <div {...rest} />}
                                    lazy={true}
                                    content={<SpeakerChangeReminder element={element} />}
                                />
                            </PopOverContainer>
                            <SpeakerLine
                                isNewSpeakerTurn={element.hasExplicitBreak}
                                isBlockEditable={element.editable}
                                isSpeakerBoxVisible={speakerBoxVisible}
                            />
                        </>
                    )}
                    {children}
                </BlockContainer>
            </VisibilityContainer>
        </Container>
    )
}
