import { Modal, Typography, Button } from '@verbit-ai/verbit-ui-library'
import { HStack } from '@chakra-ui/react'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

type TopBarPromptProps = {
    isOpen: boolean
    onClose: () => void
}

export const TopBarPrompt = (props: TopBarPromptProps) => {
    const { isOpen, onClose } = props
    const handleCancel = () => onClose()
    const analytics = useAnalytics()

    const [
        {
            context: { redirect_urls },
        },
    ] = useAppMachine(['redirect_urls'])

    const handleOrderingRedirect = () => {
        if (redirect_urls) {
            window.open(String(redirect_urls.ordering_url), '_self')
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.TOP_BAR,
                ANALYTICS_CONSTS.Session.REDIRECT_TO_ORDERING_PAGE,
            )
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={'sm'}>
            <Modal.CloseButton />
            <Modal.Header>{'Redirect to Ordering Page'}</Modal.Header>
            <Modal.Body>
                <Typography>{'Are you sure you want to leave this page?'}</Typography>
            </Modal.Body>
            <Modal.Footer>
                <HStack spacing="5px">
                    <Button variant="secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button onClick={handleOrderingRedirect}>Confirm</Button>
                </HStack>
            </Modal.Footer>
        </Modal>
    )
}
