import { useState } from 'react'
import styled from 'styled-components/macro'
import { ExitPopup } from 'src/components/Exit/ExitPopup'
import { TopBarUserMenu } from 'src/components/App/TopBarUserMenu'
import { WorkBreakPopup } from 'src/components/WorkBreak/WorkBreakPopup'
import { Header2 as VerbitHeader } from '@verbit-ai/verbit-ui-library'
import { getOrganizationIcon } from 'src/utils/header'
import { TopBarTitle } from './TopBarTitle'
import { PublishHeaderLink } from 'src/components/Publish/PublishHeaderLink'
import { TopBarHelpButton } from './TopBarHelpButton'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'
import { TopBarPrompt } from './TopBarPrompt'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

const UserSection = styled.div`
    display: flex;
    align-items: center;

    > :not(:last-child) {
        margin-right: 50px;
    }
`

const Username = styled.div`
    color: #0c2240;
    font-size: 14px;
    margin-right: 6px;
`

const UserProfileLink = styled.div`
    display: flex;
    align-items: center;
`

const StyledHeader = styled(VerbitHeader)`
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
`

interface TopBarProps {
    workerName?: string
    isUserWorking?: boolean
    isFromFallBackScreen?: boolean
}

export const TopBar = ({
    workerName,
    isUserWorking,
    isFromFallBackScreen = false,
}: TopBarProps) => {
    const [isOrderingPromptVisible, setIsOrderingPromptVisible] = useState(false)
    const analytics = useAnalytics()
    let isSupportChatEnabled = false
    const { Logo, Nav, Title } = StyledHeader
    const IconComponent = getOrganizationIcon()
    const { sessionStatus } = useSessionStatus(['sessionStatus.sessionName'])
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const handleLogoClick = () => {
        setIsOrderingPromptVisible(true)
        analytics?.sendEventTrigger(
            ANALYTICS_CONSTS.Features.TOP_BAR,
            ANALYTICS_CONSTS.Session.VERBIT_LOGO_CLICKED,
        )
    }

    if (!isFromFallBackScreen) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [{ context }] = useAppMachine(['controls'])
        const { controls } = context
        isSupportChatEnabled = controls?.supportChat?.visible && window.embedded_svc
    }

    return (
        <>
            <StyledHeader>
                <Logo onClick={handleLogoClick}>
                    <IconComponent />
                </Logo>

                {isUserWorking && <TopBarTitle />}
                {!isUserWorking && sessionStatus && (
                    <Title maxWidth={950}>{sessionStatus?.sessionName}</Title>
                )}
                <Nav>
                    {isSupportChatEnabled && <TopBarHelpButton />}
                    {workerName && (
                        <UserSection>
                            {isUserWorking && (
                                <>
                                    <PublishHeaderLink />
                                    <WorkBreakPopup />
                                    <ExitPopup />
                                </>
                            )}

                            <UserProfileLink>
                                {isRealTimeReadOnly ? (
                                    <Username>Hey, {workerName}!</Username>
                                ) : (
                                    <TopBarUserMenu workerName={workerName} />
                                )}
                            </UserProfileLink>
                        </UserSection>
                    )}
                </Nav>

                {!isFromFallBackScreen && (
                    <TopBarPrompt
                        isOpen={isOrderingPromptVisible}
                        onClose={() => setIsOrderingPromptVisible(false)}
                    />
                )}
            </StyledHeader>
        </>
    )
}
