import { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { Button } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { Typography } from '@verbit-ai/verbit-ui-library'
import { VStack } from '@chakra-ui/react'
import { useEventsMarking } from 'src/state/EventsProvider'
import { EventsList } from 'src/models/events'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

const Container = styled.div`
    align-items: center;
    background-color: ${palette('white', 0)};
    cursor: pointer;
`

const EventRow = styled.div`
    width: 100%;
    height: 54px;
    display: flex;
    border-bottom: 1px solid ${palette('grey', 13)};
    padding: 8px 16px;
    overflow: hidden;
`

const EventRowDescription = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
    margin-bottom: -2px;
`

const EventRowControls = styled.div`
    display: flex;
    min-width: 40px;
`

const StyledEventTimeCode = styled(Typography)`
    color: ${palette('grey', 8)};
    line-height: 18px;
    font-weight: 400;
`

const StyledEventName = styled(Typography)`
    line-height: 21px;
    font-weight: 400;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
`

const StyledButton = styled(Button)`
    background-color: transparent !important;

    &.bp4-button .bp4-icon {
        color: rgb(179, 186, 197);
    }

    &.bp4-button:hover .bp4-icon {
        color: #5c7080;
    }
`

const GoToPopover = styled(Popover2)`
    &.bp4-popover2-target {
        display: flex;
    }
`

export const EventsRow = () => {
    const analytics = useAnalytics()
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
    const { eventsList, setTargetIndex, searchValue } = useEventsMarking()
    const [filteredEventList, setFilteredEventList] = useState<EventsList[]>([])

    const handleTargetEvent = (eventIndex: number) => {
        analytics?.sendEventTrigger(
            ANALYTICS_CONSTS.Features.EVENTS_MARKING,
            ANALYTICS_CONSTS.EventsMarking.GO_TO_TARGET_EVENT_CLICKED,
        )
        setTargetIndex && setTargetIndex(eventIndex)
    }

    useEffect(() => {
        if (searchValue !== '') {
            const filteredData = eventsList.filter((item) =>
                item.section.toLowerCase().includes((searchValue ?? '').toLowerCase()),
            )
            setFilteredEventList(filteredData)
        }
    }, [searchValue, eventsList])

    const eventSource =
        filteredEventList.length > 0 && searchValue !== '' ? filteredEventList : eventsList

    return (
        <Container>
            {eventSource.map((event, index) => (
                <EventRow
                    key={index}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => handleTargetEvent(event.index)}
                >
                    <EventRowDescription>
                        <VStack
                            align="start"
                            key={index}
                            style={{
                                width: '100%',
                                gap: '1px',
                            }}
                        >
                            <StyledEventTimeCode size="sm">
                                {event.mediaTimeCode}
                            </StyledEventTimeCode>
                            <StyledEventName size="md">{event.section}</StyledEventName>
                        </VStack>
                    </EventRowDescription>
                    <EventRowControls>
                        {hoveredIndex === index && (
                            <>
                                <GoToPopover
                                    interactionKind="hover"
                                    position="right"
                                    content="Go to event"
                                >
                                    <StyledButton
                                        icon="locate"
                                        minimal

                                    />
                                </GoToPopover>
                            </>
                        )}
                    </EventRowControls>
                </EventRow>
            ))}
        </Container>
    )
}
