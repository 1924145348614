import { Editor } from 'slate'
import { ANALYTICS_CONSTS } from 'src/analytics'
import { debounce } from 'lodash'
import { LiveEditsEditor } from 'src/components/Editor/plugins/withLiveEdits/LiveEditsEditor'

const DEBOUNCE_TIME = 1500

type withAnalyticsOptions = {
    sendEventTrigger: (feature: string, operation: string) => void
}

// This plugin is used to send analytics events when the editor is updated specifically for the transcript (text edits)
// but doesn't include speakers, tags, annotations, events marking, or other features
// this is done to determine the accuracy of the transcript from ASR and the frequency of edits
export function withAnalytics({ sendEventTrigger }: withAnalyticsOptions) {
    return (editor: Editor) => {
        const { apply } = editor

        const handleTranscriptEditing = debounce(() => {
            // User stopped typing. Perform action here
            sendEventTrigger(
                ANALYTICS_CONSTS.Features.EDITOR,
                ANALYTICS_CONSTS.Operation.UPDATE_TRANSCRIPT,
            )
        }, DEBOUNCE_TIME)

        editor.apply = (op) => {
            apply(op)

            // If the editor is not allowed to be edited, return
            // This is to prevent sending analytics events when the editor is not in a state to be edited
            // and these includes new websocket messages, new segments, etc.
            // Re-using the function from `shouldAllowLiveEdits` to determine if the editor is allowed to be edited
            if (!LiveEditsEditor.shouldAllowLiveEdits(editor)) return

            if (op.type === 'insert_text' || op.type === 'remove_text') {
                handleTranscriptEditing()
            }
        }

        return editor
    }
}
