import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
    createContext,
    RefObject,
} from 'react'
import { createEditor, Editor, Descendant, RangeRef, Transforms, Range } from 'slate'
import { ReactEditor, Slate, withReact } from 'slate-react'
import { throttle } from 'lodash/fp'
import { withHistory } from 'slate-history'
import { useToast } from 'src/components/Toasts/ToastContext'
import { ImInfo } from 'react-icons/im'
import { TaskCache } from 'src/state/TaskCache'
import { Block } from './plugins/withTranscript'
import { convertEditorValueToPublishingData } from './convert-to-publish'
import { EditorControls, TranscriptionTask } from 'src/models'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { withPlugins } from './plugins/PluggableEditor'
import { withContextMenu } from './plugins/withContextMenu'

type EditorPopupId = 'glossary' | 'context' | 'validation'
interface EditorPopupProps {
    openPopup: (popup: EditorPopupId, range?: Range) => void
    closePopup: (selectPreviousRange?: boolean) => void
    popupRange?: RangeRef
    popupId?: EditorPopupId
}
interface EditorHighlightContextProps {
    highlightedWord: RefObject<HTMLSpanElement> | null
    setHighlightedWord: (highlightedWordRef: RefObject<HTMLSpanElement> | null) => void
}

export type EditorMode = 'basic' | 'glossers'

interface EditorMetaContextProps {
    controls: EditorControls
    editorMode: EditorMode
    taskId?: string
    sessionId?: string
    sessionName?: string
}

const EditorPopupContext = createContext<EditorPopupProps | null>(null)
const EditorHighlightContext = createContext<EditorHighlightContextProps | null>(null)
const EditorMetaContext = createContext<EditorMetaContextProps | null>(null)

interface EditorContextProps {
    plugins: Array<(editor: Editor) => Editor>
    content: Descendant[]
    onChange: (newContent: Descendant[]) => unknown
    children: React.ReactNode
    saveCache?: boolean
    task?: TranscriptionTask
    playedTimeRanges?: any
    meta: EditorMetaContextProps
}

export function EditorContext({
    plugins,
    content,
    onChange,
    children,
    saveCache,
    task,
    playedTimeRanges,
    meta,
}: EditorContextProps) {
    const [highlightedWord, setHighlightedWord] = useState<RefObject<HTMLSpanElement> | null>(null)
    const addToast = useToast()
    const [appMachine] = useAppMachine(['httpClient', 'workerId'])
    const { httpClient } = appMachine.context

    const [popupRange, setPopupRange] = useState<RangeRef | undefined>(undefined)
    const [popupId, setPopupId] = useState<EditorPopupId | undefined>()

    const popupRangeRef = useRef<RangeRef | null>(null)
    popupRangeRef.current = popupRange ?? null

    const editor = useMemo(() => {
        let editor = withContextMenu({ popupRangeRef })(
            withPlugins(withReact(withHistory(createEditor()))),
        )

        for (const plugin of plugins) {
            const tmp = plugin(editor)

            if (tmp) {
                editor = tmp
            }
        }

        return editor
    }, [plugins])

    const previousEditor = useRef(editor)
    useEffect(() => {
        if (editor !== previousEditor.current) {
            throw new Error(
                `Editor instance changed during task! ${JSON.stringify({
                    current: editor,
                    previous: previousEditor.current,
                })}`,
            )
        }
        previousEditor.current = editor
    }, [editor, previousEditor])

    const handleAutoSave = useCallback(async () => {
        let data
        if (task && playedTimeRanges) {
            data = convertEditorValueToPublishingData(editor, task, playedTimeRanges)
            if (data && data?.listened_audio && data?.listened_audio.length > 0) {
                const request = {
                    type: task.type,
                    task_id: task.id,
                    worker_id: appMachine.context.workerId,
                    auto_submit: false,
                    session_id: meta?.sessionId,
                    session_name: meta?.sessionName,
                    payload: data,
                }
                await httpClient?.autoSaveTask(task.id, request)
            }
        }
    }, [
        task,
        playedTimeRanges,
        editor,
        appMachine.context.workerId,
        meta?.sessionId,
        meta?.sessionName,
        httpClient,
    ])

    // store the popupId in a ref to avoid dependency + lifecycle issues when using openPopup()
    const popupIdRef = useRef<EditorPopupId | undefined>(popupId)
    useEffect(() => {
        popupIdRef.current = popupId
    }, [popupId])

    const openPopup = useCallback(
        (newPopupId: EditorPopupId, range: Range | null = editor.selection) => {
            let sanitizedRange
            if (range) {
                // Editor.unhangRange prevent's two paragraphs from being selected when it is not needed. (RND-2805).
                sanitizedRange = Block.getSanitizedRange(editor, {
                    at: Editor.unhangRange(editor, range),
                })
            }

            // if popup is already open, don't bother about the ranges and just change the popup
            if (!!popupIdRef.current) {
                setPopupId(newPopupId)
                return
            }

            if (!sanitizedRange) {
                popupRange?.unref()
                setPopupRange(undefined)
                return
            }

            const blockEntries = Array.from(
                Editor.nodes(editor, {
                    at: sanitizedRange,
                    match: (node) => Block.isBlock(node) && node.editable,
                }),
            )
            if (blockEntries.length > 1) {
                addToast({
                    intent: 'none',
                    message:
                        'Cannot open context menu when selection spans more than one paragraph.',
                })
                return
            }

            const ref = Editor.rangeRef(editor, sanitizedRange, { affinity: 'inward' })

            popupRange?.unref()
            setPopupRange(ref)
            setPopupId(newPopupId)

            /** clear native editor selection after popup is open to have only the tag selection */
            Transforms.deselect(editor)
        },
        [addToast, editor, popupRange],
    )

    const closePopup = useCallback(
        (selectPreviousRange?: boolean) => {
            const range = popupRange?.current

            popupRange?.unref()
            setPopupRange(undefined)
            setPopupId(undefined)

            if (range) {
                ReactEditor.focus(editor)

                if (selectPreviousRange) {
                    // select back again the range that the popup was relative to
                    Transforms.select(editor, range)
                }
            }
        },
        [editor, popupRange],
    )

    const openAutoSaveToast = useCallback(() => {
        addToast({
            intent: 'none',
            icon: (
                <ImInfo
                    size={16}
                    color="#304471"
                    style={{ margin: 12, marginRight: 0, marginTop: 18 }}
                />
            ),
            message: 'Autosave is enabled. Your work is being saved automatically.',
        })
    }, [addToast])

    const popupContext = useMemo<EditorPopupProps>(
        () => ({
            popupRange,
            popupId,
            openPopup,
            closePopup,
        }),
        [closePopup, openPopup, popupId, popupRange],
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveTranscriptSnapshotToCache = useCallback(
        throttle(5000, TaskCache.saveTranscriptSnapshot),
        [],
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttleAutoSave = useCallback(
        // default threshold is 60sec
        throttle(60000, handleAutoSave),
        [],
    )
    useEffect(() => {
        if (saveCache) {
            saveTranscriptSnapshotToCache({ document: content, timings: editor.timeline.current })
            throttleAutoSave()
        }
    }, [content, editor.timeline, saveCache, saveTranscriptSnapshotToCache, throttleAutoSave])

    const highlightedWordContext = useMemo(
        () => ({ highlightedWord, setHighlightedWord }),
        [highlightedWord, setHighlightedWord],
    )

    useMousetrap(
        ['command+s', 'ctrl+s'],
        () => {
            if (!saveCache) return
            if (openAutoSaveToast) openAutoSaveToast()
            handleAutoSave()
        },
        { label: 'Editor - Autosave key', preventDefault: true },
    )

    return (
        <Slate editor={editor} initialValue={content} onChange={onChange}>
            <EditorPopupContext.Provider value={popupContext}>
                <EditorHighlightContext.Provider value={highlightedWordContext}>
                    <EditorMetaContext.Provider value={meta}>{children}</EditorMetaContext.Provider>
                </EditorHighlightContext.Provider>
            </EditorPopupContext.Provider>
        </Slate>
    )
}

export function useEditorPopup() {
    const ctx = useContext(EditorPopupContext)

    if (!ctx) {
        throw new Error('You have forgot to use EditorContext, shame on you.')
    }

    return ctx
}

export function useHighlightedWord() {
    const ctx = useContext(EditorHighlightContext)

    if (!ctx) {
        throw new Error('You have forgot to use EditorContext, shame on you.')
    }

    return ctx
}

export const useEditorMeta = () => {
    const ctx = useContext(EditorMetaContext)

    if (!ctx) {
        throw new Error('You have forgot to use EditorContext, shame on you.')
    }

    return ctx
}
