import { useState, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { Button, Tooltip } from '@verbit-ai/verbit-ui-library'
import { InviteModal, InvitesProvider } from 'src/components/Invites'
import { BaseInviteProps } from './types'

const InviteButton = (props: BaseInviteProps) => {
    const inviteButtonRef = useRef<HTMLButtonElement>(null)
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
    const handleInviteOnClick = () => {
        setIsInviteModalOpen(!isInviteModalOpen)
        if (inviteButtonRef.current) inviteButtonRef.current.blur()
    }

    return (
        <InvitesProvider
            onMixpanelEvent={(event, data) => {
                props.onMixpanelEvent?.(event, data)
            }}
        >
            <Tooltip
                label={props.tooltipLabel || `Invite attendees to view the real time transcript`}
                placement="top"
                hasArrow
            >
                <Box>
                    <Button
                        ref={inviteButtonRef}
                        variant={props.buttonVariant || 'primarySubtle'}
                        onClick={handleInviteOnClick}
                    >
                        Invite
                    </Button>
                </Box>
            </Tooltip>

            <InviteModal
                apiToken={props.apiToken}
                orderId={props.orderId}
                inviteTemplateID={props.inviteTemplateID}
                shareLink={props.shareLink}
                modalTitle={props.modalTitle}
                subTitle={props.subTitle}
                inviteListLabel={props.inviteListLabel}
                modalRemoveInviteTitle={props.modalRemoveInviteTitle}
                isOpen={isInviteModalOpen}
                onClose={handleInviteOnClick}
            />
        </InvitesProvider>
    )
}

export default InviteButton
