import { useState, useEffect, useCallback } from 'react'
import { Modal } from '@verbit-ai/verbit-ui-library'
import {
    InviteForm,
    InvitedViewersList,
    RemoveInvitePrompt,
    useInvites,
} from 'src/components/Invites'
import { InviteModalProps } from './types'
import { InviteModalState } from '../Invites/constants'
import { CustomerUser, InvitedLists } from '../Invites/types'
import { getInvitationsList } from '../Invites/api/apiHelpers'

const InviteModal = (props: InviteModalProps) => {
    const [prevModalState, setPrevModalState] = useState<InviteModalState>(InviteModalState.FORM)
    const { isOpen, onClose } = props
    const {
        inviteModalState,
        setInviteModalState,
        setCustomerUsers,
        setInvitedViewers,
        setApiToken,
        setOrderId,
        setInviteTemplateID,
        setInviteList,
        sendMixpanelEvent,
    } = useInvites()

    const getInvitationsListOptions = useCallback(async () => {
        try {
            const res = await getInvitationsList(
                props.apiToken,
                props.orderId,
                props.inviteTemplateID,
            )
            setCustomerUsers('customer_users' in res ? (res.customer_users as CustomerUser[]) : [])
            setInvitedViewers('invitations' in res ? (res.invitations as InvitedLists[]) : [])
            return res
        } catch (error) {
            console.error('Error fetching invitations list:', error)
        }
    }, [props.apiToken, props.orderId, props.inviteTemplateID, setCustomerUsers, setInvitedViewers])

    useEffect(() => {
        if (isOpen) {
            setInviteModalState(InviteModalState.FORM)
            setPrevModalState(InviteModalState.FORM)
            props.apiToken && setApiToken(props.apiToken)
            props.orderId && setOrderId(props.orderId)
            props.inviteTemplateID && setInviteTemplateID(props.inviteTemplateID)
            getInvitationsListOptions()
        }
        return () => setInviteModalState(InviteModalState.FORM)
    }, [
        isOpen,
        setInviteModalState,
        props.apiToken,
        setApiToken,
        props.orderId,
        getInvitationsListOptions,
        setOrderId,
        props.inviteTemplateID,
        setInviteTemplateID,
    ])

    useEffect(() => {
        if (prevModalState !== inviteModalState) getInvitationsListOptions()
    }, [prevModalState, inviteModalState, getInvitationsListOptions])

    const handleRemoveInviteOnClick = () => setInviteModalState(InviteModalState.LIST)

    const handleModalOnClose = () => {
        setInviteList([])
        onClose()
        sendMixpanelEvent('Invites Modal Closed')
    }

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={handleModalOnClose}
            size={inviteModalState === InviteModalState.FORM ? 'lg' : 'md'}
        >
            {(() => {
                switch (inviteModalState) {
                    case InviteModalState.FORM:
                        return (
                            <InviteForm
                                modalTitle={props.modalTitle}
                                subTitle={props.subTitle}
                                inviteListLabel={props.inviteListLabel}
                                shareLink={props.shareLink}
                            />
                        )
                    case InviteModalState.LIST:
                        return <InvitedViewersList />
                    case InviteModalState.REMOVE_INVITE:
                        return (
                            <>
                                <InvitedViewersList />
                                <RemoveInvitePrompt
                                    isOpen={true}
                                    onClose={handleRemoveInviteOnClick}
                                    modalRemoveInviteTitle={props.modalRemoveInviteTitle}
                                />
                            </>
                        )
                    default:
                        return <></>
                }
            })()}
        </Modal>
    )
}

export default InviteModal
