import { flow, keyBy, map } from 'lodash/fp'

import {
    SessionStatus,
    SessionHealth,
    TranscriptionLayerId,
    SessionStats,
    Control,
    MenuControl,
    EventsMenuControl,
    LabelControl,
    TaskRequest,
    FeatureFlag,
    EditorControls,
    WordJSON,
    GlossaryDictJSON,
    SessionPlaybackState,
    SessionStatusState,
} from 'src/models'
import { Attachment } from 'src/models/attachment'
import { SpeakersByLegalAnnotationMap } from 'src/state/LegalAnnotationsProvider'
import { TaskRegistry } from 'src/tasks/Registry'

type MediaSources = 'zoom' | 'teams' | 'verbit_connect'

export interface AuthenticateChatClientResponseJSON {
    client_user_token: string
    jwt: {
        id: number
    }
}

export interface ErrorResponseBody {
    error: {
        message: string
        user_message: string
        critical: boolean
        code: number
        session_paused?: boolean
    }
}

export interface FeatureFlagJSON {
    feature: {
        name: string
    }
    enabled: boolean
}

export interface FeatureFlagsJSON {
    session_feature_flags?: FeatureFlagJSON[]
    worker_feature_flags?: FeatureFlagJSON[]
}

export interface RedirectURLs {
    ordering_url: string
    read_only_url: string
    verbit_connect_url: number
}

export interface WorkerStatusJSON {
    id: string
    email: string
    permitted_layers: TranscriptionLayerId[]
    state: string
    token: string
    task_id: string | null
    display_name: string
    user_id?: number
    feature_flags?: FeatureFlagsJSON
    strategy?: string
    metadata?: { fps: number }
    media_source?: MediaSources
    redirect_urls?: RedirectURLs
    sapir_channel_id?: string
    sapir_connection_url?: string
    controls?: EditorControlsJSON
}

export interface WorkerStatus {
    id: string
    email: string
    permittedLayers: TranscriptionLayerId[]
    state: string
    token: string
    taskId: string | null
    displayName: string
    userId?: number
    featureFlags?: FeatureFlagsJSON
    mediaSource?: string
    redirect_urls?: RedirectURLs
    strategy?: string
    metadata?: { fps: number }
    sapirChannelId?: string
    sapirConnectionUrl?: string
    controls?: EditorControls
}

export const toWorkerStatus = (json: WorkerStatusJSON): WorkerStatus => ({
    ...json,
    permittedLayers: json.permitted_layers,
    taskId: json.task_id,
    displayName: json.display_name,
    userId: json.user_id,
    featureFlags: json.feature_flags,
    mediaSource: json.media_source?.toLowerCase() as MediaSources,
    redirect_urls: json.redirect_urls,
    sapirChannelId: json.sapir_channel_id,
    sapirConnectionUrl: json.sapir_connection_url,
    controls: json.controls && toEditorControls(json.controls),
})

export enum WorkBreakError {
    NotAllowed = 'low_rest_time',
    MaxRestingWorkerLimitReached = 'max_resting_reached',
}

export interface SessionStatusJSON {
    status: {
        session_started_at: string
        tags_modified_at: string
        speakers_modified_at: string
        workers_modified_at: string
        playback_state: string
        state: string
        is_active: boolean
    }
    metadata: {
        audio_url: string
        media_type: 'audio' | 'video'
        attachments: any
        guidelines: string | null
        session_id: string
        session_name: string
        worker_redirect_url: string
        vertical: string | null
        is_training: boolean
        customer_id?: string | number | undefined
        customer_name?: string
        plat_job_id?: number
        audio_started_at?: string
        order_id: string
    }
    worker: {
        id: string
        email: string
        permitted_layers: TranscriptionLayerId[]
        rest_time?: {
            position_in_queue: number
            available_time: number
            is_allowed: boolean
            min_rest_time: number
            num_resting_workers: number
            reason: WorkBreakError
            work_rest_ratio: number
        }
    }
}

export interface TaskBaseJSON {
    id: string
    timeout_at: string
    assigned_at: string
    min_submit_at: string
    server_time: string
    layer_id: 'edit' | 'annotate' | 'review'
    auto_submit: boolean
    metadata?: { fps: number }
}

interface EditorControlsJSON {
    diarization: Control
    speaker: Control
    section: MenuControl
    events: EventsMenuControl
    label: LabelControl
    legal: {
        annotation: MenuControl
        exhibit: Control
        auto_population: {
            overwrite: boolean
        }
    }
    audio: {
        auto_play: boolean
        force_listen: boolean
        report_listened: boolean
        max_buffer_time?: number
    }
    runtime_timestamp?: { visible: boolean }
    timer?: { visible: boolean }
    timecode?: { visible: boolean }
    live_timecode?: { visible: boolean }
    publish_button?: {
        position: string
        visible: boolean
    }
    toolbar?: {
        visible: boolean
        finish_layer: { visible: boolean }
        media_component: { visible: boolean }
        link_to_read_only: { visible: boolean }
        download_transcript: { visible: boolean }
    }
    edit_disable?: boolean
    suggestions?: boolean
    realtime_read_only?: boolean
    support_chat?: { visible: boolean }
}

export interface LegalSection {
    type: string
    text: string
    start: number
}

export interface TranscriptionTaskJSON extends TaskBaseJSON {
    type: 'transcription'
    payload: {
        controls: EditorControlsJSON
        start: number
        end: number
        segments: {
            before: {
                start: number
                end: number
                words: WordJSON[]
            }
            after: {
                start: number
                end: number
                words: WordJSON[]
            }
            body: {
                start: number
                end: number
                words: WordJSON[]
            }
        }
        annotation_map: SpeakersByLegalAnnotationMap
        section: LegalSection | null
    }
}

export interface WorkerResponsibility {
    id: string
    text: string
}

export type Role = 'Corrector' | 'Annotator' | 'Proofer' | 'Glosser'

export interface SessionIntroductionStepJSON {
    type: 'session_intro'
    payload: {
        controls: EditorControlsJSON
        title: string
        started_at: string | null
        role_display_name: Role
        responsibilities: WorkerResponsibility[]
        attachments: Attachment[] | null
        vertical: string | null
        session_name: string
        role_switch?: boolean | null
    }
}

export interface GuidelinesStepJson {
    type: 'guidelines'
    payload: {
        guidelines: string
    }
}

export interface TeamIntroductionStepJSON {
    type: 'team_intro'
    payload: {
        team: Array<{
            layer_id: string
            role_display_name: Role
            count: number
            online_count: number
        }>
        controls: EditorControlsJSON
    }
}

export interface GlossaryHighlightsStepJSON {
    type: 'gloss_highlights'
    payload: GlossaryDictJSON
}

export interface SpeakersIntroductionStepJSON {
    type: 'speakers_intro'
}

export interface TranscriptIntroStepJSON {
    type: 'transcript_intro'
    payload: {
        controls: EditorControlsJSON
        start: number
        end: number
        body: {
            start: number
            end: number
            words: WordJSON[]
        }
    }
}

export interface TranscriptLatestStepJSON {
    type: 'transcript_latest'
    payload: {
        controls: EditorControlsJSON
        start: number
        end: number
        body: {
            start: number
            end: number
            words: WordJSON[]
        }
        section: LegalSection | null
    }
}

export type OnboardingStepJSON =
    | SessionIntroductionStepJSON
    | TeamIntroductionStepJSON
    | GlossaryHighlightsStepJSON
    | SpeakersIntroductionStepJSON
    | TranscriptIntroStepJSON
    | TranscriptLatestStepJSON
    | GuidelinesStepJson
    | AttachmentsStepJSON

export interface OnboardingTaskJSON extends TaskBaseJSON {
    type: 'onboarding'
    payload: {
        steps: OnboardingStepJSON[]
        controls: EditorControlsJSON
    }
}

export interface RestTaskJSON extends TaskBaseJSON {
    type: 'resting'
    payload: {
        resting_time: number
    }
}

export interface PrePopulationTaskJson extends TaskBaseJSON {
    type: 'gloss_population'
    payload: {
        attachments: Attachment[]
    }
}

export interface AttachmentsStepJSON extends TaskBaseJSON {
    type: 'attachments'
    payload: {
        attachments: Attachment[]
    }
}

export interface TestTaskJSON extends TaskBaseJSON {
    type: 'test'
    payload: {
        foo: 'bar'
    }
}

export type TaskJSON =
    | OnboardingTaskJSON
    | TranscriptionTaskJSON
    | RestTaskJSON
    | TestTaskJSON
    | PrePopulationTaskJson

export type TaskRequestJSON = (
    | {
          availability: 'UNAVAILABLE_TEMPORARY' | 'UNAVAILABLE_PERMANENT' | 'PAUSED'
          task: null
      }
    | {
          availability: 'NEW_TASK' | 'EXISTING_TASK'
          task: TaskJSON
      }
) & {
    playback_state: 'NOT_STARTED' | 'PLAYING' | 'PAUSED' | 'STOPPED'
    session_resources: {
        speakers_modified_at: string
        tags_modified_at: string
    }
}

export const toEditorControls = (json: EditorControlsJSON): EditorControls => ({
    diarization: json.diarization,
    speaker: json.speaker,
    section: json.section,
    events: json.events,
    label: json.label,
    legal: {
        annotation: json.legal.annotation,
        exhibit: json.legal.exhibit,
        autoPopulation: json.legal.auto_population,
    },
    audio: {
        autoplay: json.audio.auto_play,
        forceListen: json.audio.force_listen,
        reportListened: json.audio.report_listened,
        bufferingTimeoutInSeconds: json.audio.max_buffer_time,
    },
    runtimeTimestamp: json.runtime_timestamp,
    timer: json.timer,
    timecode: json.timecode,
    liveTimecode: json.live_timecode,
    publishButton: json.publish_button,
    toolbar: json.toolbar,
    editable: !json.edit_disable,
    suggestionsEnabled: json.suggestions,
    realtimeReadOnly: json.realtime_read_only,
    supportChat: json.support_chat,
})

export const toTaskRequest = (json: TaskRequestJSON): TaskRequest => {
    switch (json.availability) {
        case 'PAUSED':
        case 'UNAVAILABLE_TEMPORARY':
        case 'UNAVAILABLE_PERMANENT': {
            return {
                availability: json.availability,
                task: null,
                playbackState: json.playback_state,
                sessionResources: {
                    speakersModifiedAt: new Date(json.session_resources?.speakers_modified_at),
                    tagsModifiedAt: new Date(json.session_resources?.tags_modified_at),
                },
            }
        }

        case 'EXISTING_TASK':
        case 'NEW_TASK': {
            return {
                availability: json.availability,
                playbackState: json.playback_state,
                task: TaskRegistry.convertToTask(json.task),
                sessionResources: {
                    speakersModifiedAt: new Date(json.session_resources?.speakers_modified_at),
                    tagsModifiedAt: new Date(json.session_resources?.tags_modified_at),
                },
            }
        }
    }
}

export type SessionType = 'training_service' | 'verbatizer' | null

export interface SessionStatsJSON {
    session_created_by: SessionType
    worker_id: string
    state: string
    stats: {
        work_time: number
        transcribed_audio: number
        segments_completed: number
        earned: number | null
        user_message: string
        user_message_title: string
        auto_redirect_delay?: number
    }
}

export interface SessionHealthJSON {
    status: {
        state: string
    }
    worker_redirect_url: string
}

export const toSessionHealth = (json: SessionHealthJSON): SessionHealth => ({
    status: json.status.state,
    redirectUrl: json.worker_redirect_url,
})

export const toStats = (json: SessionStatsJSON): SessionStats => ({
    workTime: json.stats.work_time,
    transcribedAudio: json.stats.transcribed_audio,
    segmentsCompleted: json.stats.segments_completed,
    earned: json.stats.earned,
    state: json.state,
    userMessage: json.stats.user_message,
    userMessageTitle: json.stats.user_message_title,
    redirectCountdownMs: json.stats.auto_redirect_delay,
    isTrainingSession: json.session_created_by === 'training_service',
})

export const toSessionStatus = (json: SessionStatusJSON): SessionStatus => ({
    worker: {
        id: json.worker.id,
        name: json.worker.email,
        layerIds: json.worker.permitted_layers,
    },
    vertical: json.metadata.vertical,
    isTraining: json.metadata.is_training,
    active: json.status.is_active,
    startedAt: new Date(json.status.session_started_at),
    tagsModifiedAt: new Date(json.status.tags_modified_at),
    speakersModifiedAt: new Date(json.status.speakers_modified_at),
    workersModifiedAt: new Date(json.status.workers_modified_at),
    playbackState: json.status.playback_state as SessionPlaybackState,
    state: json.status.state as SessionStatusState,
    audioUrl: json.metadata.audio_url,
    mediaType: json.metadata.media_type,
    attachments: json.metadata.attachments,
    guidelines: json.metadata.guidelines,
    sessionId: json.metadata.session_id,
    sessionName: json.metadata.session_name,
    orderId: json.metadata.order_id,
    audioStartedAt: json.metadata.audio_started_at ?? '',
    transcribersHubUrl: json.metadata.worker_redirect_url,
    customerId: (json.metadata.customer_id && String(json.metadata.customer_id)) || undefined,
    customerName: json.metadata.customer_name,
    platformJobId: json.metadata.plat_job_id,
    workBreakStatus: {
        positionInQueue: json.worker?.rest_time ? json.worker?.rest_time.position_in_queue : null,
        isAllowed: json.worker?.rest_time?.is_allowed ?? false,
        notAllowedReason: json.worker?.rest_time?.reason,
        availableTime: json.worker?.rest_time?.available_time ?? 0,
        workBreakRatio: json.worker?.rest_time?.work_rest_ratio ?? 0,
        minTimeForWorkBreak: json.worker?.rest_time?.min_rest_time ?? 0,
    },
})

const toFeatureFlag = ({ feature, enabled }: FeatureFlagJSON): FeatureFlag => ({
    name: feature.name,
    enabled,
})

const toFeatureFlagDict = flow(
    map<FeatureFlagJSON, FeatureFlag>(toFeatureFlag),
    keyBy<FeatureFlag>('name'),
)

export const toFeatureFlags = (json: FeatureFlagsJSON): { [featureName: string]: FeatureFlag } => {
    const workerLevelFeatureFlagDict = toFeatureFlagDict(json.worker_feature_flags)
    const sessionLevelFeatureFlagDict = toFeatureFlagDict(json.session_feature_flags)

    /** let the session level flags override the worker level flags,
     *  because they have a higher priority **/
    const mergedFeatureFlagDict = {
        ...workerLevelFeatureFlagDict,
        ...sessionLevelFeatureFlagDict,
    }

    return mergedFeatureFlagDict
}
